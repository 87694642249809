import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const colored = defineStyle(({ colorScheme = 'brand' }) => ({
  color: `${colorScheme}.500`,
  _hover: {
    color: `${colorScheme}.600`,
  },
}));

const animated = defineStyle(({ colorScheme = 'brand' }) => ({
  display: 'inline-block',
  position: 'relative',
  _hover: {
    color: `${colorScheme}.500`,
  },
  _after: {
    content: "''",
    position: 'absolute',
    width: 'full',
    transform: 'scaleX(0)',
    height: '2px',
    bottom: 0,
    left: 0,
    backgroundColor: `${colorScheme}.500`,
    transformOrigin: 'bottom right',
    transition: 'transform 0.25s ease-in-out',
  },
  '&:hover:after': {
    transform: 'scaleX(1)',
    transformOrigin: 'bottom left',
  },
}));

export const Link = defineStyleConfig({
  baseStyle: {
    _hover: {
      textDecoration: 'none',
    },
  },
  variants: {
    colored,
    animated,
    'colored-and-animated': (p) => ({ ...colored(p), ...animated(p) }),
  },
  defaultProps: {
    variant: 'colored-and-animated',
  },
});
