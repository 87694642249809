import { accordionAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(accordionAnatomy.keys);

const separated = definePartsStyle({
  container: {
    borderTopWidth: 0,
    background: 'white',
    mb: 6,
    p: 4,
    borderRadius: 'md',
    boxShadow: 'md',
    overflow: 'hidden',
    '&:last-of-type': {
      borderBottomWidth: 0,
    },
  },
  button: {
    '&:hover': {
      bg: 'transparent',
    },
  },
});

export const Accordion = defineMultiStyleConfig({ variants: { separated } });
