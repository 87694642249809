import * as Accordion from './Accordion';
import * as Button from './Button';
import * as Checkbox from './Checkbox';
import * as Input from './Input';
import * as Link from './Link';
import * as Tooltip from './Tooltip';
import * as Tabs from './Tabs';
import * as Table from './Table';

export const components = {
  ...Accordion,
  ...Button,
  ...Checkbox,
  ...Input,
  ...Link,
  ...Tooltip,
  ...Tabs,
  ...Table,
};
