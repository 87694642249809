import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const baseStyle = definePartsStyle({
  control: {
    border: '2px solid',
    borderColor: 'gray.300',
    borderRadius: 'sm',
  },
});

export const Checkbox = defineMultiStyleConfig({ baseStyle });
