'use client';

import { createContext, useContext, useEffect, useRef } from 'react';
import {
  ChakraProvider,
  useDisclosure,
  UseDisclosureReturn,
} from '@chakra-ui/react';
import { usePathname } from 'next/navigation';
import { SessionProvider } from 'next-auth/react';

import theme, { toastOptions } from '@raise/theme';

import IntercomProvider from './Analytics/Intercom';
import Dialogs from './Dialogs';

import { RF_CALLBACK } from '../(public)/auth/verify-request/utils';

interface DialogObject {
  waitlistDialog: UseDisclosureReturn;
  videoDialog: UseDisclosureReturn;
  sidebarDisclosure: UseDisclosureReturn;
  addNewButtonRef: React.RefObject<HTMLButtonElement>;
}

const RootContext = createContext({});
export const useRootContext = () =>
  useContext<Partial<DialogObject>>(RootContext);

export default function Providers({
  session,
  children,
}: {
  session: any;
  children: React.ReactNode;
}): JSX.Element {
  // Main layout context
  const waitlistDialog = useDisclosure();
  const videoDialog = useDisclosure();

  // App layout context
  const sidebarDisclosure = useDisclosure();
  const addNewButtonRef = useRef(null);

  // Router context
  const pathname = usePathname();

  // Ref to store the previous pathname
  const previousPathnameRef = useRef<string | null>(null);

  // A useEffect that detects incoming changes to the router
  // Specifically if the user is coming from a scholar profile page (scholars/[hashid]) and going to the signup page (auth/register),
  // or login page (auth/login), then add store the callbackUrl in localStorage
  useEffect(() => {
    // Get the previous pathname
    const previousPathname = previousPathnameRef.current;

    // Update the ref with the current pathname for the next render
    previousPathnameRef.current = pathname;

    // Define regex to match the scholar profile path
    const scholarProfileRegex = /^\/scholars\/[^/]+$/;

    // Define the target routes
    const targetRoutes = ['/auth/login', '/auth/register'];

    // If the user is coming from a scholar profile page and going to the signup page,
    // or login page, then add the callbackUrl in localStorage
    if (
      previousPathname &&
      scholarProfileRegex.test(previousPathname) &&
      targetRoutes.includes(pathname || '')
    ) {
      const callbackUrl = encodeURIComponent(previousPathname);

      localStorage.setItem(RF_CALLBACK, callbackUrl);
    }
  }, [pathname]);

  return (
    <SessionProvider session={session}>
      <IntercomProvider>
        <RootContext.Provider
          value={{
            waitlistDialog,
            videoDialog,
            sidebarDisclosure,
            addNewButtonRef,
          }}
        >
          <ChakraProvider theme={theme} toastOptions={toastOptions}>
            {children}
            <Dialogs />
          </ChakraProvider>
        </RootContext.Provider>
      </IntercomProvider>
    </SessionProvider>
  );
}
