import {
  extendTheme,
  withDefaultColorScheme,
  type ToastProviderProps,
} from '@chakra-ui/react';

import { colors, brandedColors } from './colors';
import { components } from './components';
import { fonts } from './typography';

const theme = extendTheme(
  {
    styles: {
      global: {
        [`#t-modal.t-modal`]: {
          zIndex: `3000 !important`,
        },
      },
    },
    colors: {
      ...colors,
      ...brandedColors,
    },
    components,
    fonts,
  },
  withDefaultColorScheme({ colorScheme: 'brand' }),
);

export default theme;

export const toastOptions: ToastProviderProps = {
  defaultOptions: { position: 'bottom-left', variant: 'left-accent' },
};
