import client, { coalesce, localeData } from '@lib/integrations/sanity';

import { GetDataProps } from './types';

export async function getData(): Promise<GetDataProps> {
  return {
    // @ts-ignore Sanity-typed can't generate types like this
    forms: await client.fetch(coalesce('forms'), localeData),
    // @ts-ignore Sanity-typed can't generate types like this
    common: await client.fetch(coalesce('common'), localeData),
    toasts: await client.fetch(coalesce('toasts'), localeData),
  };
}
