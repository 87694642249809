import { tableAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tableAnatomy.keys);

const baseStyle = definePartsStyle({
  thead: {
    bg: 'gray.100',
  },
  th: {
    fontFamily: 'body',
  },
});

const simple = definePartsStyle({
  th: {
    borderColor: 'gray.200',
  },
  td: {
    borderColor: 'gray.200',
  },
});

export const Table = defineMultiStyleConfig({
  baseStyle,
  variants: { simple },
});
