import { Inter, JetBrains_Mono } from 'next/font/google';
import localFont from 'next/font/local';

const inter = Inter({
  subsets: ['latin'],
  display: 'swap',
});

const jetbrainsMono = JetBrains_Mono({
  subsets: ['latin'],
  display: 'swap',
});

const recoleta = localFont({
  src: [
    {
      path: './fonts/recoleta/recoleta-semibold-webfont.woff2',
      weight: '600',
      style: 'normal',
    },
  ],
});

const splitFont = (font: any) => font.style.fontFamily.split(', ');

const body = [
  ...splitFont(inter),
  'ui-sans-serif',
  'system-ui',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  '"Noto Sans"',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
  '"Noto Color Emoji"',
];

export const fonts = {
  body: body.join(', '),
  heading: [...splitFont(recoleta), ...body].join(', '),
  mono: [...splitFont(jetbrainsMono), ...body].join(', '),
};
