'use client';

import { useEffect, useState } from 'react';

import { getData } from './data';
import { GetDataProps } from './types';
import VideoDialog from './VideoDialog';
import WaitlistDialog from './WaitlistDialog';

import { useRootContext } from '../Providers';

export default function Dialogs(): JSX.Element | null {
  const [content, setContent] = useState<GetDataProps>();
  const { waitlistDialog, videoDialog } = useRootContext();

  useEffect(() => {
    getData().then(({ forms, common, toasts }) =>
      setContent({ forms, common, toasts }),
    );
  }, []);

  if (!content?.common || !content?.forms || !waitlistDialog || !videoDialog)
    return null;

  return (
    <>
      <WaitlistDialog
        dialog={waitlistDialog}
        common={content.common.common}
        forms={content.forms}
        toasts={content.toasts}
      />
      <VideoDialog dialog={videoDialog} id={content.common.common.video_id} />
    </>
  );
}
