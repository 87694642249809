import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys);

const softRounded = definePartsStyle({
  tab: {
    borderRadius: 'lg',
  },
  tablist: {
    gap: 2,
    flexWrap: 'wrap',
  },
});

export const Tabs = defineMultiStyleConfig({
  variants: { 'soft-rounded': softRounded },
});
