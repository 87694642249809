import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  UseDisclosureReturn,
} from '@chakra-ui/react';

import Waitlist from '@components/Waitlist';
import type {
  CommonDocument,
  FormsDocument,
  ToastsDocument,
} from '@raise/sanity';

type Props = {
  dialog: UseDisclosureReturn;
  common: CommonDocument['common'];
  forms: FormsDocument;
  toasts: ToastsDocument;
};

const WaitlistDialog: React.FC<Props> = ({ dialog, common, forms, toasts }) => (
  <Modal {...dialog} size="xl" preserveScrollBarGap>
    <ModalOverlay />
    <ModalContent p={4}>
      <ModalHeader fontSize="2xl">{common.waitlist.dialog.title}</ModalHeader>
      <ModalCloseButton />
      <ModalBody mb={4}>
        <Text mb={6} fontSize="lg">
          {common.waitlist.dialog.message}
        </Text>
        <Waitlist {...common} forms={forms} toasts={toasts} />
      </ModalBody>
    </ModalContent>
  </Modal>
);

export default WaitlistDialog;
