import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const ghost = defineStyle(({ colorScheme = 'brand' }) => ({
  bg: `${colorScheme}.50`,
  _hover: {
    bg: `${colorScheme}.100`,
  },
}));

export const Button = defineStyleConfig({
  variants: { ghost },
});
