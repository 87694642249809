import { inputAnatomy, numberInputAnatomy } from '@chakra-ui/anatomy';
import {
  createMultiStyleConfigHelpers,
  defineStyle,
  defineStyleConfig,
} from '@chakra-ui/react';

const {
  definePartsStyle: defineInputPartsStyle,
  defineMultiStyleConfig: defineInputMultiStyleConfig,
} = createMultiStyleConfigHelpers(inputAnatomy.keys);

const {
  definePartsStyle: defineNumberPartsStyle,
  defineMultiStyleConfig: defineNumberMultiStyleConfig,
} = createMultiStyleConfigHelpers(numberInputAnatomy.keys);

const commonBorderStyles = {
  borderColor: 'gray.300',
  _hover: { borderColor: 'gray.400' },
  _focus: { borderColor: `gray.700` },
};

const outline = {
  field: {
    backgroundColor: 'white',
    border: '2px solid',
    ...commonBorderStyles,
  },
};

const flushed = {
  field: {
    borderBottom: '2px solid',
    ...commonBorderStyles,
  },
};

export const Input = defineInputMultiStyleConfig({
  variants: {
    outline: defineInputPartsStyle(outline),
    flushed: defineInputPartsStyle(flushed),
  },
  defaultProps: {
    // @ts-ignore This is actually correct, but Chakra's types are wrong
    focusBorderColor: 'gray.700',
  },
});

export const Select = Input;

const numberInputProps = {
  stepper: {
    borderInlineStartWidth: '0px',
    _last: { borderTopWidth: '0px' },
  },
  stepperGroup: {
    m: '2px',
    h: 'calc(100% - 4px)',
  },
};

export const NumberInput = defineNumberMultiStyleConfig({
  variants: {
    outline: defineNumberPartsStyle({
      ...outline,
      ...numberInputProps,
    }),
    flushed: defineNumberPartsStyle({
      ...flushed,
      ...numberInputProps,
    }),
  },
  defaultProps: {
    // @ts-ignore This is actually correct, but Chakra's types are wrong
    focusBorderColor: 'gray.700',
  },
});

export const Textarea = defineStyleConfig({
  variants: {
    outline: defineStyle(outline.field),
    flushed: defineStyle(flushed.field),
  },
  defaultProps: {
    // @ts-ignore This is actually correct, but Chakra's types are wrong
    focusBorderColor: 'gray.700',
  },
});
