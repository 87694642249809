import YouTube from 'react-youtube';
import {
  AspectRatio,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  UseDisclosureReturn,
} from '@chakra-ui/react';

import type { CommonDocument } from '@raise/sanity';

type Props = {
  dialog: UseDisclosureReturn;
  id: CommonDocument['common']['video_id'];
};

const VideoDialog: React.FC<Props> = ({ dialog, id }) => (
  <Modal {...dialog} size="4xl" isCentered preserveScrollBarGap>
    <ModalOverlay />
    <ModalContent overflow="hidden">
      <ModalBody p={0}>
        <AspectRatio
          ratio={16 / 9}
          sx={{
            '#layout-video': {
              width: '100%',
              height: '100%',
            },
          }}
        >
          <YouTube
            videoId={id}
            onReady={(e: any) => e.target.playVideo()}
            id="layout-video"
          />
        </AspectRatio>
      </ModalBody>
    </ModalContent>
  </Modal>
);

export default VideoDialog;
